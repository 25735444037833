<template>
  <div>
    <van-search
      v-model="trackingNo"
      shape="round"
      show-action
      @search="onSearch"
      :placeholder="$t('搜索快递单号')"
      use-action-slot
    >
      <van-button
        slot="action"
        type="default"
        size="small"
        round
        @click="onSearch"
      >
        {{ $t("搜索") }}
      </van-button>
      <!-- <div slot="action" bind:tap="onSearchClick">搜索</div> -->
    </van-search>

    <div class="cargo-list">
      <div
        class="cargo-list-item"
        v-for="item in searchResult"
        :key="item.id"
        @click="toCargoDetail(item)"
      >
        <van-image
          width="125"
          height="75"
          radius="5px"
          v-if="item.label"
          fit="cover"
          :src="item.label"
        />
        <van-image
          width="125"
          height="75"
          radius="5px"
          v-else
          fit="contain"
          :src="require('@/assets/icons/send-inactive.png')"
        />
        <div class="cargo-desc">
          <div class="cargo-desc-title">{{ item.trackingNo }}</div>
          <div class="secondary-text">
            {{ formatTime(item) }}
            {{ $t(cargoStatus(item)) }}
          </div>
          <div class="cargo-desc-remark">
            {{ item.remark ? item.remark : "" }}
          </div>
        </div>
        <van-icon name="arrow" />
      </div>
    </div>

    <van-empty
      v-if="!searchResult.length"
      image="search"
      :description="$t('暂时没有找到相关运单')"
    />
  </div>
</template>

<script>
import {
  Image as VanImage,
  Search,
  List,
  Button,
  Toast,
  Empty,
  Icon,
} from "vant";
import { formatCargoStatusTime } from "@/utils/utils";
import { convertCargoStatus } from "@/utils/dict";
import { getCargoList } from "@/service/api";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Search.name]: Search,
    [Button.name]: Button,
    [List.name]: List,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Icon.name]: Icon,
  },
  data() {
    return {
      trackingNo: "",
      searchResult: [],
    };
  },
  mounted() {
    this.trackingNo = this.$route.query.trackingNo;
    this.getCargoList();
  },
  methods: {
    formatTime(item) {
      return formatCargoStatusTime(item);
    },
    cargoStatus(item) {
      return convertCargoStatus(item.status, item.paymentStatus);
    },
    onSearch() {
      if (!this.trackingNo) {
        Toast(this.$t("请输入要搜索的快递单号"));
        return;
      }
      this.getCargoList();
    },
    toCargoDetail(item) {
      this.$router.push({ name: "cargoDetail", query: { id: item.id } });
    },
    async getCargoList() {
      if (!this.trackingNo) {
        return;
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const res = await getCargoList({ prematchTrackingNo: this.trackingNo });

      if (res.success) {
        this.searchResult = res.data;
        Toast.clear();
      } else Toast.fail(res.message);
    },
  },
};
</script>
<style lang="less" scoped>
.van-search__action {
  line-height: normal !important;
}

.cargo-list {
  padding: 5px;
}

.cargo-list-item {
  display: flex;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.cargo-list-item .label-image {
  width: 125px;
  height: 75px;
  border-radius: 5px;
  margin-right: 10px;
}

.cargo-list-item .cargo-desc {
  flex-grow: 2;
  line-height: 25px;
  margin-left: 10px;
}
</style>